<template>
  <main class="md:ml-12 mb-8 mr-4 md:mr-0">
    <trac-loading v-if="isLoading" />
    <trac-validation-observer slim v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveProfile)">
        <div class="wrapper mb-8">
          <ul class="flex-col mt-12">
            <li><a @click="$router.back()" href="#">Go Back</a></li>
            <li class="font-bold mt-4 text-2xl">Company Information</li>
          </ul>
        </div>
        <div
          class="px-8 py-8 bg-white h-full rounded-lg overflow-hidden big-shadow"
        >
          <div class="grid md:flex flex-row md:divide-x">
            <div class="mb-10 md:mb-0 md:w-3/12 mr-5 px-10">
              <div
                class="w-full h-auto relative"
                v-if="business.logo || imageToUpload.src"
              >
                <img
                  class="h-40 w-full"
                  :src="imageToUpload.src || business.logo"
                  alt=""
                />
                <input
                  class="absolute bottom-0"
                  accept="image/png, image/jpeg, image/jpg"
                  @change="selectImage"
                  type="file"
                  name=""
                  id="product-image"
                  v-show="false"
                />
                <button
                  @click="removeImage"
                  class="w-full p-2 text-sm bg-primaryGray text-white"
                >
                  Remove Image
                </button>
              </div>
              <div class="flex-wrap flex-column w-2/12" v-else>
                <div class="flex w-48 h-40 no-img-bg relative">
                  <input
                    class="absolute bottom-0"
                    accept="image/png, image/jpeg, image/jpg"
                    @change="selectImage"
                    type="file"
                    name=""
                    id="product-image"
                    v-show="false"
                  />
                  <div
                    @click="triggerFileOpen"
                    class="flex w-48 cursor-pointer h-10 bg-primaryGray mt-auto text-white justify-center items-center font-medium text-sm"
                  >
                    Upload Image
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-column md:w-9/12 md:px-10">
              <div class="grid md:grid-cols-2 gap-10">
                <trac-validation-provider
                  name="company name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="business.name"
                    placeholder="Company Name"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-validation-provider
                  name="street"
                  rules=""
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="business.address.street"
                    placeholder="Street"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
              </div>

              <div class="grid md:grid-cols-2 gap-10 mt-12">
                <trac-validation-provider
                  name="city"
                  rules=""
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="business.address.city"
                    placeholder="City"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-validation-provider
                  name="state"
                  rules=""
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="business.address.state"
                    placeholder="State"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
              </div>
              <div class="grid md:grid-cols-2 gap-10 mt-12">
                <trac-validation-provider
                  name="country"
                  rules=""
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="business.address.country"
                    placeholder="Country"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-validation-provider
                  name="phone"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <trac-input
                    v-model="business.phone"
                    placeholder="Phone Number"
                  ></trac-input>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>
              </div>
              <div class="grid md:grid-cols-2 gap-10 mt-12">
                <trac-input
                  v-model="business.email"
                  disabled
                  placeholder="Email"
                ></trac-input>
                <!-- <trac-input v-model="business.website" class="w-6/12" placeholder="Website"></trac-input> -->
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-5 justify-end">
          <trac-button type="submit" class="uppercase"
            >Save changes</trac-button
          >
        </div>
      </form>
    </trac-validation-observer>
  </main>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";
import FraudCheck from './FraudCheck';
export default {
  name: "CompanyInfo",
  mixins: [FraudCheck],
  data() {
    return {
      isLoading: false,
      business: null,
      imageToUpload: {
        src: null,
        file: null,
      },
    };
  },
  created() {
    this.business = GET_USER_BUSINESS_DATA();
    this.confirmIfFraud(true);
  },
  methods: {
    triggerFileOpen() {
      const fileInput = document.querySelector("#product-image");
      fileInput.click();
    },
    removeImage() {
      this.imageToUpload = {
        src: null,
        file: null,
      };
      this.business.logo = "";
    },
    selectImage(e) {
      this.imageToUpload.file = e.target.files[0];
      this.imageToUpload.src = window.URL.createObjectURL(
        this.imageToUpload.file
      );
    },
    async saveProfile() {
      this.isLoading = true;
      if (this.imageToUpload.file) {
        await this.uploadProductImage();
      }
      await this.updateProfile();
      this.isLoading = false;
    },
    async uploadProductImage() {
      await this.$store.dispatch("UPLOAD_FILE", this.imageToUpload);
      const res = this.$store.getters["GET_UPLOAD_RES"];

      this.$store.commit("SETUP_FILE_UPLOADED", null);

      if (res.status) {
        this.business.logo = res.data;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async updateProfile() {
      const payload = {
        name: this.business.name,
        phone: this.business.phone,
        image: this.business.logo || "",
        address: {
          street: this.business.address.street,
          city: this.business.address.city,
          state: this.business.address.state,
          country: this.business.address.country,
        },
      };
      await this.$store.dispatch("UPDATE_USER_PROFILE", payload);
      const res = this.$store.getters["GET_COMPANY_INFO_RESPONSE"];
      if (res.status) {
        let businessData = GET_USER_BUSINESS_DATA();
        businessData.address = payload.address;
        businessData.phone = payload.phone;
        businessData.name = payload.name;
        businessData.logo = payload.image;

        SAVE_LOCAL_DB_DATA(
          "traction-app-current-user-business-data",
          businessData  
        );

        // Check if it's from update for company info
        if (GET_LOCAL_DB_DATA("invoice-details-for-profile-update")) {
          this.$router.back();
        }
      }
      // alert(res.message);
      eventBus.$emit("trac-alert", { message: res.message });
    },
  },
};
</script>

<style scoped>
.no-img-bg {
  background-color: #e2e2e2;
}
</style>
