export default {
    data() {
        return {
            isLoading: false,
            isFraud: false,
            showFraudModal: false
        }
    },
    methods: {
        confirmIfFraud(navigateIfFraudulent = false) {
            this.isLoading = true;
            this.$store.dispatch("FETCH_BUSINESS_USER_DETAILS").then((res) => {
              if (res.status) {
                this.isFraud = res.data.IsFraud || false
                this.showFraudModal = true
                this.navigateCheck(this.isFraud, navigateIfFraudulent)
              } else {
                eventBus.$emit("trac-alert", {
                  message: res.message,
                });
              }
              this.isLoading = false;
            });
          },
          navigateCheck(isFraud, navigateIfFraudulent = false) {
            if (isFraud && navigateIfFraudulent) {
              this.$router.push({ name: 'CompanyInfoDisabled' });
            }
          }
    },
}